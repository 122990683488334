import React from 'react';
import classes from './Header.module.css';
import {Link} from "react-router-dom";

const MyComponent = () => {
    return (
        <div></div>
        // <header className={classes.header}>
        //     <nav>
        //         <button><Link to='/'>Home</Link></button>
        //         <button><Link to='/room/2'>Room</Link></button>
        //         <button><Link to='/stomp'>Stomp</Link></button>
        //     </nav>
        // </header>
    );
};

export default MyComponent;
