import React, {ReactNode} from 'react';
import Header from "./Header";
import classes from './Layout.module.css';

interface Props {
    children: ReactNode
}

const MyComponent = (props: Props) => {
    return (
        <>
            <Header/>
            <main className={classes.content}>
                { props.children }
            </main>
        </>
    );
};

export default MyComponent;
