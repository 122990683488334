export const LIGHTS = [
    'light01',
    'light02',
    'light03',
    'light04'
]

export const SERVOS = [
    'garageServo'
]

export const DOORS = [
    'doorSensor'
]

export const TEMPS = [
    'tempSensor01'
]

export const FANS = [
    'fan01'
]
