import React, {useEffect, useState} from 'react';
import classes from './Favorites.module.css';
import Device from "./Device";
import {useSelector} from "react-redux";
import {AppStore} from "../redux/store";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {PublishMessageModel} from "../pages/Home";

interface Props {
    onPublishDeviceAction: (message: PublishMessageModel) => void
}
const Favorites = (props: Props) => {

    const favorites = useSelector((state: AppStore) => state.favorites);
    let toggleDeviceHandler = (apiName: string, status: string) => {
        console.log('[PUBLISHING MESSAGE] ', apiName , ' / ', status)
        const message: PublishMessageModel = {
            apiName: apiName,
            state: status
        }
        props.onPublishDeviceAction(message);
    }

    let content;
    if (favorites.length !== 0) {
        content = favorites.map((device) => {
            return (
                <Device
                    key={device.id}
                    onClick={() => {
                    }}
                    onToggle={toggleDeviceHandler}
                    device={device}/>
            )
        })
    } else {
        content =
            <>
                <SkeletonTheme baseColor="#EFF5F5"
                               width={'100%'}
                               height={'100%'}
                               highlightColor="#D6E4E5">
                    <Skeleton count={1}
                              height={100}/>
                    <Skeleton count={1}
                              height={100}/>
                    <Skeleton count={1}
                              height={100}/>
                    <Skeleton count={1}
                              height={100}/>
                    <Skeleton count={1}
                              height={100}/>
                </SkeletonTheme>
            </>;
    }

    return (
        <div className={classes.container}>
            {content}
        </div>
    );
};

export default Favorites;
