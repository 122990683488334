import React, {useEffect, useMemo, useState} from 'react';
import './App.css';
import Layout from "./layout/Layout";
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Room from "./pages/Room";
import Stomp from "./pages/Stomp";
import {Provider} from "react-redux";
import store from "./redux/store";
import {Amplify, PubSub} from "aws-amplify";
import {AWSIoTProvider} from '@aws-amplify/pubsub/lib-esm/Providers';


Amplify.configure({
    Auth: {
        identityPoolId: 'us-east-1:049a928e-a33b-47fc-a49e-9f46fb4981af',
        region: 'us-east-1',
        userPoolId: 'us-east-1_JQhMErkMD',
        userPoolWebClientId: '51vi16nl0i3c9sih5h0lm9bo4e'
    }
});
Amplify.addPluggable(new AWSIoTProvider({
    aws_pubsub_region: 'us-east-1',
    aws_pubsub_endpoint: `wss://a17020cczd6d7t-ats.iot.us-east-1.amazonaws.com/mqtt`,
}));
PubSub.configure({
    Auth: {
        identityPoolId: 'us-east-1:049a928e-a33b-47fc-a49e-9f46fb4981af',
        region: 'us-east-1',
        userPoolId: 'us-east-1_JQhMErkMD',
        userPoolWebClientId: '51vi16nl0i3c9sih5h0lm9bo4e'
    }
});

function App() {


    const [state, setState] = useState('message');

    useEffect(() => {

        return () => {

        };
    }, []);

    console.log(process.env)
    return (
        <Provider store={store}>
            <Layout>
                <Routes>
                    <Route path='/'
                           element={<Home/>}></Route>
                    <Route path='/room/:id'
                           element={<Room/>}></Route>
                    <Route path='/stomp'
                           element={<Stomp/>}></Route>
                </Routes>
            </Layout>
        </Provider>
    );
}

export default App;
