import React, {useEffect, useMemo, useState} from 'react';
import {Client, StompConfig} from "@stomp/stompjs";
// @ts-ignore
import SockJS from "sockjs-client";

const Stomp = () => {

    const [message, setMessage] = useState<string>('asd');
    const [input, setInput] = useState<string>('');

    const stompConfig: StompConfig = {
        connectHeaders: {},
        // brokerURL: 'http://localhost/gs-guide-websocket',
        webSocketFactory: () => {
            // return new SockJS('http://localhost:8080/gs-guide-websocket');
             let url ='http://domotic-env.eba-5jikhm62.us-east-1.elasticbeanstalk.com:80/gs-guide-websocket';
            let url1 ='http://localhost:8080/gs-guide-websocket';

            return new SockJS(url1, {debug: true})
            //localStorage.debug = "*";
        },
        debug: (message) => {
            console.dir(message);
        },
        reconnectDelay: 200,
        onConnect: (frame) => {
            console.log('Connected');
            const subscription = stompClient.subscribe(
                "/topic/greetings",
                (message) => {
                    setMessage(message.body);
                })
        }
    }

    let stompClient = useMemo(() => new Client(stompConfig), []);

    useEffect(() => {
        console.log('Activando')
        stompClient.activate();
    }, [])

    const sendMessage = () => {
        stompClient.publish(
            {
                destination: '/app/hello',
                body: JSON.stringify({name: input})
            }
        )
    }

    return (
        <div>
            <input type='text'
                   value={input}
                   onChange={
                       (e) => {
                           setInput(prevState => {
                               e.preventDefault();
                               return e.target.value
                           })
                       }
                   }/>
            <button onClick={sendMessage}>Send Message</button>
            <p>{message}</p>
        </div>
    );
};

export default Stomp;
