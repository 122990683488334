import {Client, StompConfig} from "@stomp/stompjs";
// @ts-ignore
import SockJS from "sockjs-client";
import {Middleware} from "@reduxjs/toolkit";
import {wsConnect} from "../states/websocket-toolkit";
import * as actions from "../states/websocket";
import {setFavorites} from "../states/favorites";

const stompMiddleare = () => {

    const stompConfig: StompConfig = {
        connectHeaders: {},
        // brokerURL: 'http://localhost/gs-guide-websocket',
        webSocketFactory: () => {
            // return new SockJS('http://localhost:8080/gs-guide-websocket');
            let url ='http://domotic-env.eba-5jikhm62.us-east-1.elasticbeanstalk.com:80/gs-guide-websocket';
            let url1 ='http://localhost:8080/gs-guide-websocket';

            return new SockJS(url1, {debug: true})
            //localStorage.debug = "*";
        },
        debug: (message) => {
            console.dir(message);
        },
        reconnectDelay: 200,
        onConnect : () => {
            console.log('Connected');
            const subscription = socket.subscribe(
                "/topic/greetings",
                (message) => {
                    console.log(message);
                })
        }
    }

    // @ts-ignore
    const onConnect = () => {
        console.log('Connected');
        const subscription = socket.subscribe(
            "/topic/greetings",
            (message) => {
                console.log(message);
            })
    }

    const onMessage = (store: any) => (event: any) => {
        const payload = JSON.parse(event.data);
        console.log('RECEIVING SERVER MESSAGE: ');

        switch (payload.type) {
            case 'update_device_state':
                store.dispatch(setFavorites(payload.data));
                break;
            default:
                break;
        }
    }

    let socket: Client;

    const middleware: Middleware = store => next => action => {
        // console.log(action);
        if (wsConnect.match(action)) {
            if (socket !== null) {
                // socket.deactivate();
            }
            socket = new Client(stompConfig);
            socket.activate();
            console.log('CONNECT')
            // socket.onConnect = onConnect;
            // socket.on = onMessage(store);
            // socket.onclose = onClose(store);
            // socket.onopen = onOpen(store);
        }
        next(action);
    }

    return middleware;

}

export default stompMiddleare();
