import React, {useEffect, useState} from 'react';
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import classes from './Rooms.module.css';
import {rooms as mock} from "../mock/data/rooms";
import Room from "./Room";
import RoomModel from "../models/room.model";
import {useNavigate} from "react-router-dom";
import {getLocalStorage, setLocalStorage} from "../utils/local-storage.util";
import {useSelector} from "react-redux";
import {AppStore} from "../redux/store";

const Rooms = () => {

    let navigate = useNavigate();
    const devices = useSelector((state: AppStore) => state.favorites);
    let roomsArray: any[] = [];
    devices.forEach(device => {
        let foundRoom = roomsArray.find(rd => rd.id === device.room);
        if (foundRoom) {
            foundRoom['devices'] += 1;
        } else {
            let img;
            let name;
            if (device.room === 'livingRoom') {
                name = 'Living Room'
                img = 'images/living-room.jpg'
            } else if (device.room === 'bathroom') {
                name = 'Bathroom'
                img = 'images/bathroom.jpg'
            } else if (device.room === 'garage') {
                name = 'Garage'
                img = 'images/garage.jpg'
            } else if (device.room === 'bedroom01') {
                name = 'First Bedroom'
                img = 'images/room-1.jpg'
            } else if (device.room === 'bedroom02') {
                name = 'Second Room'
                img = 'images/kitchen.jpg'
            }
            roomsArray.push(
                {
                    id: device.room,
                    img: img,
                    name: name,
                    devices: 1
                }
            )
        }
    })
    // console.log(roomsArray)

    const [rooms, setRooms] = useState<RoomModel[]>([]);
    useEffect(() => {
        setRooms(roomsArray);
    }, [devices]);

    // useEffect(() => {
    //
    //     const roomsFromLocalStorage = getLocalStorage('rooms');
    //     if (roomsFromLocalStorage) {
    //         setRooms(JSON.parse(roomsFromLocalStorage));
    //     }
    //
    //     const timeout = setTimeout(() => {
    //         if (!roomsFromLocalStorage) {
    //             setRooms(mock);
    //             setLocalStorage('rooms', mock);
    //         }
    //     }, 1500)
    //
    //     return () => {
    //         clearTimeout(timeout);
    //     }
    // }, []);

    const redirectHandler = (roomId: string) => {
        navigate(`/room/${roomId}`);
    };

    const roomsItems = roomsArray.map(r => {
        return (
            <Room onClick={redirectHandler}
                  room={r}
                  key={r.id}/>
        );
    });

    const skeleton = <><Skeleton count={1}/>
        <Skeleton count={1}/>
        <Skeleton count={1}/>
        <Skeleton count={1}/></>;

    return (
        <>
            <SkeletonTheme baseColor="#EFF5F5"
                           width={'100%'}
                           height={'100%'}
                           highlightColor="#D6E4E5">
                <div className={classes.rooms}>
                    {
                        rooms[0] ? roomsItems : skeleton
                    }
                </div>
            </SkeletonTheme>


        </>
    );
};

export default Rooms;
