import React from 'react';
import classes from "./Room.module.css";
import RoomModel from "../models/room.model";

interface Props {
    room: RoomModel,
    onClick(id: string): void
}


const Room = (props: Props) => {

    const clickHandler = () => {
        props.onClick(props.room.id);
    }

    return (
        <div onClick={clickHandler} className={classes.room} key={props.room.id}>
            <div className={classes.imageContainer}>
                <img src={props.room.img}  alt={props.room.name}/>
            </div>
            <div className={classes.roomInfo}>
                <span>{props.room.name}</span>
                <span>{props.room.devices} devices</span>
            </div>
        </div>
    );
};

export default Room;
