import React, {useEffect} from 'react';
import classes from './Home.module.css';
import Rooms from "../components/Rooms";
import Favorites from "../components/Favorites";
import 'react-loading-skeleton/dist/skeleton.css';
import {AnyAction, ThunkDispatch} from "@reduxjs/toolkit";
import Device from "../models/device.model";
import {useDispatch, useSelector} from "react-redux";
import {AppStore} from "../redux/store";
import {fetchFavorites} from "../redux/states/favorites-action";
import {PubSub} from "aws-amplify";
import {updateDeviceState} from "../redux/states/favorites";

export interface PublishMessageModel {
    apiName: string,
    state: string
}

const Home = () => {

    type AppDispatch = ThunkDispatch<Device[], any, AnyAction>
    const dispatch: AppDispatch = useDispatch();
    const favorites = useSelector((state: AppStore) => state.favorites);
    console.log(favorites)

    useEffect(() => {
        dispatch(fetchFavorites());
        PubSub.subscribe('esp32/updates').subscribe({
            next: (data: any) => {
                console.log('Message received', data)
                console.warn(data.value)
                dispatch(updateDeviceState(data.value))
            },
            error: (error: any) => console.error(error),
            // close: () => console.log('Done'),
        });

    }, []);

    let publishMessage = (message: PublishMessageModel) => {
        PubSub.publish('esp32/actions', message).then(r => console.log(r));
    }

    return (
        <>
            <section className={classes.greetings}>
                <span>Good Evening,</span>
                <h3>Profe Lenin</h3>
            </section>
            <section>
                <div className={classes.content}>
                    <span>Favourite Devices</span>
                    <Favorites onPublishDeviceAction={publishMessage}/>
                </div>
                <div className={classes.content}>
                    <span>Rooms</span>
                    <Rooms/>
                </div>
            </section>
        </>
    );
};

export default Home;
