import React from 'react';
import DeviceModel from "../models/device.model";
import classes from "./Device.module.css";
import {DEVICE_TYPE} from "../enums/device-type.enum";
import {PublishMessageModel} from "../pages/Home";
import {
    BsDoorClosedFill,
    BsDoorOpenFill, BsFillArrowDownSquareFill, BsFillArrowUpCircleFill,
    BsFillLightbulbFill,
    BsFillLightbulbOffFill, BsThermometerHigh,
    BsTornado,
    BsWind
} from "react-icons/bs";
import {IconContext} from "react-icons";

interface Props {
    device: DeviceModel,

    onClick(id: string): void,

    onToggle: (apiName: string, status: string) => void
}

const Device = (props: Props) => {

    const clickHandler = () => {
        props.onClick(props.device.id);
    }

    let publishMessage: PublishMessageModel = {
        apiName: '',
        state: ''
    }

    const isTogglable = (
        props.device.type === DEVICE_TYPE.LIGHT ||
        props.device.type === DEVICE_TYPE.FAN ||
        props.device.type === DEVICE_TYPE.SERVO
    )

    if (props.device.type === DEVICE_TYPE.LIGHT ||
        props.device.type === DEVICE_TYPE.FAN) {
        publishMessage = {
            apiName: props.device.apiName,
            state: props.device.state === 'on' ? '0' : '1'
        }
    }

    if (props.device.type === DEVICE_TYPE.SERVO) {
        publishMessage = {
            apiName: props.device.apiName,
            state: props.device.state === 'opened' ? '0' : '1'
        }
    }

    let iconColor = (props.device.state === 'on' || props.device.state === 'opened') ? '00ebba' :
        (props.device.state === 'closed' || props.device.state === 'off') ? '549fe5' : '00ebba'

    let icon
    if (props.device.type === 'light') {
        if (props.device.state === 'on') {
            icon = <IconContext.Provider value={{color: iconColor, size: '30px'}}>
                <BsFillLightbulbFill/>
            </IconContext.Provider>
        } else {
            icon = <IconContext.Provider value={{color: iconColor, size: '30px'}}>
                <BsFillLightbulbOffFill/>
            </IconContext.Provider>
        }
    } else if (props.device.type === 'door') {
        if (props.device.state === 'opened') {
            icon = <IconContext.Provider value={{color: iconColor, size: '30px'}}>
                <BsDoorOpenFill/>
            </IconContext.Provider>
        } else {
            icon = <IconContext.Provider value={{color: iconColor, size: '30px'}}>
                <BsDoorClosedFill/>
            </IconContext.Provider>
        }
    } else if (props.device.type === 'fan') {
        icon = <IconContext.Provider value={{color: iconColor, size: '30px'}}>
            <BsWind/>
        </IconContext.Provider>
    } else if (props.device.type === 'tempSensor') {
        icon = <IconContext.Provider value={{color: iconColor, size: '30px'}}>
            <BsThermometerHigh/>
        </IconContext.Provider>
    } else if (props.device.type === 'servo') {
        if (props.device.state === 'opened') {
            icon = <IconContext.Provider value={{color: iconColor, size: '30px'}}>
                <BsFillArrowDownSquareFill/>
            </IconContext.Provider>
        } else {
            icon = <IconContext.Provider value={{color: iconColor, size: '30px'}}>
                <BsFillArrowUpCircleFill/>
            </IconContext.Provider>
        }
    }


    let classState;
    classState = (props.device.state === 'off' || props.device.state === 'closed') ? classes['device-off'] :
        (props.device.state === 'on' || props.device.state === 'opened') ? classes['device-on'] : '';

    let state;
    state = props.device.state === 'off' ? 'INACTIVE' :
        props.device.state === 'on' ? 'ACTIVE' : '';

    return (
        <div onClick={() => props.onToggle(publishMessage.apiName, publishMessage.state)}
             className={`${classes.device} ${classState}`}
             key={props.device.id}>
            <div className={classes.container}>
                <div className={classes.icon}>
                    {icon}
                </div>
                <div className={classes.info}>
                    <span>{props.device.name}</span>
                    {
                        (typeof props.device.room === 'string') && <span>{props.device.room}</span>
                    }
                    {
                        props.device.type === DEVICE_TYPE.TEMP &&
                        <span>Temp: {props.device.state} C°</span>
                    }
                    {
                        props.device.type !== DEVICE_TYPE.TEMP &&
                        <span className={classes.state}> {props.device.state.toUpperCase()} </span>
                    }
                </div>
            </div>


        </div>
    );
};

export default Device;
