import Device from "../../models/device.model";
import {createSlice} from "@reduxjs/toolkit";
import {DOORS, FANS, LIGHTS, TEMPS} from "../../enums/devices.enum";

const initialState: Device[] = [];

export const favoritesSlice = createSlice(
    {
        name: 'favorites',
        initialState: initialState,
        reducers: {
            setFavorites: (state, action) => {
                return action.payload;
            },
            addFavorite: (state, action) => {
                return state.concat(action.payload);
            },
            removeFavorite: (state, action) => {
                const filtered = state.filter((d: Device) => d.id !== action.payload);
                return filtered;
            },
            updateDeviceState: (state, action) => {
                const apiName = action.payload.apiName;
                const newDeviceState = String(action.payload.status);
                console.log(apiName)
                if (LIGHTS.includes(apiName)) {
                    // @ts-ignore
                    const device = state.find(device => device.apiName === apiName);
                    // @ts-ignore
                    device.state = newDeviceState === '0' ? 'off' : 'on';
                } else if (TEMPS.includes(apiName)) {
                    const device = state.find(device => device.apiName === apiName);
                    // @ts-ignore
                    device.state = newDeviceState;
                    console.log('NEW TEMP')
                } else if(DOORS.includes(apiName)) {
                    const device = state.find(device => device.apiName === apiName);
                    // @ts-ignore
                    device.state = newDeviceState;
                } else if(FANS.includes(apiName)) {
                    // @ts-ignore
                    const device = state.find(device => device.apiName === apiName);
                    // @ts-ignore
                    device.state = newDeviceState === '0' ? 'off' : 'on';
                }

            }
        }
    }
)

export const { setFavorites, addFavorite, removeFavorite, updateDeviceState } = favoritesSlice.actions;

export default favoritesSlice.reducer;
