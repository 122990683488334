import {configureStore} from "@reduxjs/toolkit";
import {favoritesSlice} from "./states/favorites";
import Device from "../models/device.model";
import middleware from "./middleware/middleware";
import {webSocketSlice} from "./states/websocket-toolkit";
import stompMiddleware from "./middleware/stompMiddleware";

export interface AppStore {
    favorites: Device[]
}

const store = configureStore(
    {
        reducer: {
            favorites: favoritesSlice.reducer,
            websocket: webSocketSlice.reducer
        },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(stompMiddleware)
    }
)

export default store;
