import {setFavorites} from "./favorites";
import {AnyAction, ThunkAction} from "@reduxjs/toolkit";
import Device from "../../models/device.model";

export const fetchFavorites = (): ThunkAction<void, Device[], unknown, AnyAction> => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(
                'https://ojbgiivni1.execute-api.us-east-1.amazonaws.com/Prod/devices'
            );
            if (!response.ok) {
                throw new Error('Failed fetching favorite devices data');
            }
            let data = await response.json();
            return data;
        };

        try {
            const favoritesData = await fetchData();
            dispatch(setFavorites(favoritesData));
        } catch (error) {
            // IDK JDK
        }
    }
}
