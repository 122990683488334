import {createSlice} from "@reduxjs/toolkit";
import {favoritesSlice} from "./favorites";


export const webSocketSlice = createSlice(
    {
        name: 'websocket',
        initialState: '',
        reducers: {
            wsConnect: (state, action) => {
                return action.payload;
            },
        }
    }
)

export const { wsConnect } = webSocketSlice.actions;

export default webSocketSlice.reducer;
